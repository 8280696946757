<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_24_6)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128.297 18.9631C81.1684 18.9631 42.9631 57.1684 42.9631 104.297C42.9631 145.965 64.8424 179.268 87.6723 202.643C99.0275 214.269 110.41 223.216 118.953 229.251C122.72 231.912 125.919 233.996 128.297 235.482C130.676 233.996 133.874 231.912 137.641 229.251C146.184 223.216 157.567 214.269 168.922 202.643C191.752 179.268 213.631 145.965 213.631 104.297C213.631 57.1684 175.426 18.9631 128.297 18.9631ZM128.297 246.52C123.742 254.836 123.739 254.834 123.735 254.832L123.701 254.814L123.626 254.772L123.373 254.631C123.161 254.511 122.86 254.34 122.479 254.119C121.715 253.676 120.624 253.031 119.252 252.186C116.509 250.497 112.637 248.007 108.011 244.739C98.7767 238.216 86.455 228.536 74.1063 215.893C49.5284 190.729 24 152.921 24 104.297C24 46.6954 70.6954 0 128.297 0C185.899 0 232.594 46.6954 232.594 104.297C232.594 152.921 207.066 190.729 182.488 215.893C170.139 228.536 157.817 238.216 148.583 244.739C143.957 248.007 140.085 250.497 137.342 252.186C135.97 253.031 134.879 253.676 134.116 254.119C133.734 254.34 133.434 254.511 133.221 254.631L132.969 254.772L132.893 254.814L132.868 254.827C132.864 254.829 132.852 254.836 128.297 246.52ZM128.297 246.52L132.852 254.836C130.014 256.39 126.573 256.387 123.735 254.832L128.297 246.52ZM128.297 75.8524C112.588 75.8524 99.8524 88.5875 99.8524 104.297C99.8524 120.007 112.588 132.742 128.297 132.742C144.007 132.742 156.742 120.007 156.742 104.297C156.742 88.5875 144.007 75.8524 128.297 75.8524ZM80.8893 104.297C80.8893 78.1145 102.114 56.8893 128.297 56.8893C154.48 56.8893 175.705 78.1145 175.705 104.297C175.705 130.48 154.48 151.705 128.297 151.705C102.114 151.705 80.8893 130.48 80.8893 104.297Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
